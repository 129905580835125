.mapContainer {
  width: 300px;
  height: 300px;
  max-width: 100%;
}

.description {
  width: 100%;
  white-space: pre-line;
}
