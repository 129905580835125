.wrapper {
  height: 100%;
  padding-bottom: 3rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  text-align: justify;

  .mainDescription {
    font-size: 1.2rem;

    // TODO make a more generic link styling
    a {
      text-decoration: underline;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap-reverse;

    button {
      padding: 1rem;
      font-size: 1.2rem;
    }
  }

  .projects {
    max-width: 20rem;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 1rem;
    text-align: center;

    .project,
    .connection {
      display: grid;
      grid-template-rows: 1fr 1fr;
      align-items: start;
      justify-items: center;
      gap: 0.5rem;

      .logo {
        height: 6rem;
        width: 6rem;
        object-fit: contain;
      }
    }

    .plus {
      font-size: 3rem;
      height: 6rem;
      display: flex;
      align-items: center;
    }
  }

  .spacer {
    flex: auto;
  }

  .footer {
    a {
      text-decoration: underline;
    }
  }
}
