.container {
  // make sure the maps won't be overflowing other components
  position: relative;
  z-index: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;

  padding-bottom: 2rem;

  .header {
    align-self: start;
    * {
      vertical-align: text-bottom;
    }
  }
}

.accommodation {
  border: 2px solid black;
  padding: 1rem;
  position: relative;
  max-width: 100%;
  width: calc(300px + 2rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  label {
    font-size: small;
    margin-bottom: -1rem;
  }

  .errorMessage {
    font-size: small;
    color: red;
    margin-top: -1rem;
  }

  .inputError {
    border: 2px solid red;
  }

  textarea {
    width: 100%;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    button {
      flex: auto;
    }
  }
}

.addAccommodationButton {
  width: calc(304px + 2rem);
  max-width: 100%;
}
