.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .person {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .name {
      font-size: 1.5rem;
    }
  }

  .accommodation {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    white-space: pre-line;
  }
}
