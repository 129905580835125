@use 'styles/helpers';

.container {
  // position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // top: 4rem;
  // bottom: 4rem;
  background-color: lightgray;
  position: relative;
  height: 100%;

  @include helpers.remove-page-margins;
}

.offerOverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  // flex: 0;
  padding: 2rem 35px;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: #fffe;

  .closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

.mapContainer {
  width: 100%;
  height: 100%;
  z-index: 0;
}
