@import-normalize;
@import 'styles/preflight';

// fix some production style inconsistencies
button,
fieldset,
input[type='submit'],
input[type='reset'] {
  border: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

// by default, show svg inline (icons)
svg {
  display: initial;
}

@import 'styles/generic';
@import 'styles/form';
@import 'styles/menu';

@import 'leaflet/dist/leaflet';
@import 'leaflet.markercluster/dist/MarkerCluster';
@import 'leaflet.markercluster/dist/MarkerCluster.Default';
