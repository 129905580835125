.photo {
  // height: 2rem;
  // width: 2rem;
  border-radius: 1000rem;
  display: inline-block;
  object-fit: cover;

  &.square {
    border-radius: 0;
  }
}
