.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 1.2rem;

  // customize style of buttons on home
  a {
    padding: 1rem;
    max-width: 20rem;
    width: 100%;
    gap: 0.8rem;
  }
}
