.text {
  text-align: justify;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem 0 0.75rem;
  }

  // TODO make a more generic link styling
  a {
    text-decoration: underline;
  }

  p {
    margin: 0.75rem 0;
  }
}
