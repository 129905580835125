.button {
  padding: 0.25rem 0.5rem;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  &.primary {
    border: 2px solid black;
    background-color: black;
    color: white;
    text-decoration: none;

    &:hover {
      background-color: lighten(black, 20);
    }
  }

  &.secondary {
    border: 2px solid black;
    color: black;
    text-decoration: none;

    &:hover {
      background-color: darken(white, 10);
    }
  }

  &.tertiary {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: black;
  }

  &.danger {
    border: 2px solid red;
    background-color: red;
    text-decoration: none;

    &:hover {
      border: 2px solid darken(red, 10);
      background-color: darken(red, 10);
    }
  }
}
