.container {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;

  form {
    display: contents;
  }

  input {
    width: 100%;
  }

  label {
    font-size: small;
    // remove gap after label
    margin-bottom: -1rem;
  }

  // styles for uploading image
  .uploadButton {
    height: 250px;
    width: 250px;
    max-width: 100%;

    background-color: #222;
    font-size: xx-large;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-bottom: 0;
    cursor: pointer;

    // place image in the middle
    background-size: cover;
    background-position: center;
  }

  input[type='file'] {
    display: none;
  }
}
