.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .name {
    font-size: 1.75rem;

    svg {
      font-size: 1rem;
      vertical-align: super;
    }
  }

  .about {
    white-space: pre-wrap;
  }

  .photo {
    width: 250px;
    height: 250px;
    max-width: 100%;
    object-fit: cover;
  }
}
